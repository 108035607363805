(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["_", "react"], factory);
	else if(typeof exports === 'object')
		exports["storm-react-diagrams"] = factory(require("lodash"), require("react"));
	else
		root["storm-react-diagrams"] = factory(root["_"], root["React"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 